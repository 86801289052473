<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_title">{{ $t("Machine.Equipment") }}</div>
      <div class="machine_search">
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Custom.Serial") }}</div>
          <div class="search_selected">
            <el-input
              :label="$t('AddMachine.GroupSimple')"
              v-model.trim="groupName"
              :placeholder="$t('AddMachine.inputSerialNumber')"
              clearable
            />
          </div>
        </div>
        <div class="machine_search_btn">
          <div
            class="search_btn"
            @click="searchGroup"
            :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Machine.Search") }}
          </div>
        </div>
        <div class="machine_search_btn">
          <div
            class="search_btn"
            @click="reset"
            :style="{ background: isBlue ? '#3660DD' : '#4875ef' }"
          >
            {{ $t("Warning.Reset") }}
          </div>
        </div>
        <div class="machine_search_space"></div>
      </div>
      <div class="table_content">
        <el-table
          ref="multipleTable"
          :data="tableData"
          @row-click="rowClick"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }"
          highlight-current-row
          style="width: 100%"
          row-key="id"
          :expand-row-keys="expandRowKeys"
          @expand-change="expandChange"
        >
          <el-table-column width="10" align="center" />
          <el-table-column type="expand" min-width="10%">
            <template slot-scope="props">
              <el-table
                ref="multipleTable"
                :data="props.row.authorities"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="{ background: '#dde0e8' }"
                @selection-change="handleSelectionChange"
              >
                <el-table-column width="70" align="center" />
                <el-table-column
                  type="selection"
                  width="55"
                  v-if="props.row.is_yudo"
                >
                </el-table-column>
                <el-table-column
                  :label="$t('Custom.Order')"
                  min-width="20%"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div>{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Custom.Number')"
                  prop="number"
                  min-width="20%"
                  show-overflow-tooltip
                />
                <el-table-column
                  min-width="20%"
                  :label="$t('Machine.Serial')"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div
                      v-clipboard:copy="scope.row.serial_number"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                    >
                      {{ scope.row.serial_number }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="20%"
                  :label="$t('Setting.CreationTime')"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.createtime | time }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('AddMachine.Operation')"
                  width="120"
                  align="center"
                  v-if="props.row.is_yudo"
                >
                  <template slot-scope="scope" slot="header">
                    <img
                      src="../../assets/image/in.png"
                      @click="inDia(scope.row)"
                      class="OperationImg mgr"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('AddMachine.Operation')"
                  width="120"
                  align="center"
                  v-if="!props.row.is_yudo"
                >
                  <template slot-scope="scope">
                    <img
                      src="../../assets/image/out.png"
                      @click="OutDia(scope.row)"
                      class="OperationImg mgr"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
            min-width="15%"
            :label="$t('Custom.Company')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.is_yudo ? $t("Custom.NotMachine") : scope.row.name
                }}
              </div>
            </template>
          </el-table-column>

          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :page-size="pagesize"
          :page-sizes="[5, 10, 15]"
          :current-page.sync="page"
          :total="total"
          v-show="total && total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <el-dialog
      :title="$t('Custom.CustomSelect')"
      :visible.sync="dialogVisibles"
      width="364px"
      class="dialog"
    >
      <el-form ref="workShopForm" label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Custom.Custom") }}
            </div>
            <el-select
              v-model="workshop"
              @change="changeWorkshop"
              :placeholder="$t('Custom.SelectCustom')"
            >
              <el-option
                :label="item.name"
                v-model="item.id"
                v-for="item in workshopList"
                :key="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn"
          style="margin-right: 0px; background: #4875ef"
          @click="handleCus"
          :style="{ background: isBlueConfire ? '#3660DD' : '#4875ef' }"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn"
          @click="dialogVisibles = false"
          style="background: #767e95"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('Login.Account')"
      :visible.sync="isCodes"
      width="364px"
      class="dialogs"
    >
      <div class="form-box" style="text-align: left; margin-bottom: 10px">
        {{ account }}
      </div>
      <el-form ref="workShopForm" :model="codes" label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left; font-size: 18px">
              {{ $t("Login.Password") }}
            </div>
            <el-input
              v-model.trim="codes.machineCode"
              type="password"
              :placeholder="$t('Login.inputPwd')"
              ref="input1"
              autocomplete="new-passowrd"
              clearable
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn bts"
          style="margin-right: 0px; background: #4875ef"
          @click="confireCode"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn bts"
          @click="isCodes = false"
          style="background: #767e95"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "../../style/table.less";
import { getSerial, withdraw, transfer } from "@/api/custom";

export default {
  name: "Serial",
  data() {
    return {
      Startime: "",
      Endtime: "",
      dialogVisible: false,
      dialogVisibles: false,
      groupName: "",
      workShopId: "",
      workshop: "",
      repeat: true,
      repeatTip: true,
      repeatSearch: true,
      password: "",
      type: "add",
      repeatCopy: true,
      codes: {
        machineCode: "",
      },
      Serial: {
        name: "",
      },
      tableData: [],
      account: "",
      company_id: "",
      serial_number: "",
      expandRowKeys: [],
      tableList: [],
      isBlue: false,
      repeatOut: false,
      isCodes: false,
      isBlueSearch: false,
      isBlueConfire: false,
      repeatCustom: true,
      repeatPwd: true,
      total: 0,
      types: "",
      page: 1,
      pagesize: 15,
      multipleSelection: [],
    };
  },
  created() {
    this.getSerial("", "type");
    this.account = localStorage.getItem("account");
  },
  computed: {
    workshopList() {
      return this.tableList.filter(function (data, index) {
        // 只返回id是偶数的
        return index !== 0;
      });
    },
  },
  filters: {
    time(item) {
      const date = new Date(item * 1000);
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      const hour =
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      return (
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute
      );
    },
  },
  methods: {
    onCopy() {
      if (!this.repeatCopy) {
        return;
      }
      this.repeatCopy = false;
      setTimeout(() => {
        this.repeatCopy = true;
      }, 2000);
      this.$message({
        showClose: true,
        message: this.$t("Tip.CopySuccessful"),
        type: "success",
        duration: 1000,
      });
    },
    onError() {
      this.$message({
        showClose: true,
        message: this.$t("Tip.CopyFailed"),
        type: "error",
        duration: 1000,
      });
    },
    handleCus() {
      if (!this.repeatCustom) {
        return;
      }
      this.repeatCustom = false;
      setTimeout(() => {
        this.repeatCustom = true;
      }, 2000);
      if (!this.workshop) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.SelectCustom"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.dialogVisibles = false;
      this.isCodes = true;
      this.types = "in";
    },
    changeWorkshop() {},
    confireCode() {
      if (!this.repeatPwd) {
        return;
      }
      this.repeatPwd = false;
      setTimeout(() => {
        this.repeatPwd = true;
      }, 2000);
      if (!this.codes.machineCode) {
        this.$message({
          showClose: true,
          message: this.$t("Login.inputPwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      const params = {};

      params.serial_number = this.serial_number;
      params.password = this.$md5(this.codes.machineCode);
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      if (!this.codes.machineCode) {
        this.$message({
          showClose: true,
          message: this.$t("Setting.IpPwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (this.types === "out") {
        params.company_id = this.company_id;
        withdraw(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Custom.OutSuccess"),
              type: "success",
              duration: 1000,
            });
            this.isCodes = false;
            this.getSerial("", "type", "out");
          }
        });
      } else if (this.types === "in") {
        transfer(
          {
            company_id: this.workshop,
            password: this.$md5(this.codes.machineCode),
            serial_numbers: this.multipleSelection
              .map((item) => item.serial_number)
              .join(","),
          },
          token,
          lang
        ).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Custom.InSuccess"),
              type: "success",
              duration: 1000,
            });
            this.isCodes = false;
            this.getSerial("", "type", "in");
          }
        });
      }
    },
    getData() {
      const params = {};
      params.page = this.page;
      params.pagesize = this.pagesize;
      params.serial_number = "";
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("lang");
      getSerial(params).then((res) => {
        this.tableList = res.data.list;
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    // 转移设备
    inDia(val) {
      if (!this.multipleSelection.length) {
        return 
      }
      this.codes.machineCode = "";
      this.dialogVisibles = true;
      this.workshop = "";
      // this.company_id = val.company_id;
      // this.serial_number = val.serial_number;
      this.getData();
    },
    OutDia(val) {
      this.codes.machineCode = "";
      this.isCodes = true;
      this.types = "out";
      this.company_id = val.company_id;
      this.serial_number = val.serial_number;
    },
    changeIpt(e) {
      this.$forceUpdate();
    },
    reset() {
      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      this.groupName = "";
      this.getSerial("", "type");
    },

    searchGroup() {
      if (!this.repeatSearch) {
        return;
      }
      this.repeatSearch = false;
      setTimeout(() => {
        this.repeatSearch = true;
      }, 2000);
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      this.getSerial(this.groupName, "", "");
    },
    async getSerial(val, type, item) {
      if (!val && !type) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Criteria"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {};
      params.page = this.page;
      params.pagesize = this.pagesize;
      params.serial_number = val;
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("lang");
      await getSerial(params).then((res) => {
        this.tableData = res.data.list;

        this.total = res.data.count;
        this.noDataTable = this.total === 0;
        if (!item) {
          this.expandRowKeys = [];
        }
        if (val && !item) {
          for (var i = 0; i < this.tableData.length; i++) {
            this.expandRowKeys.push(this.tableData[i].id);
          }
        }
        if (type && item && item === "out") {
          this.expandRowKeys = [];
          this.expandRowKeys.push(this.tableData[0].id);
          this.company_id = "";
          this.serial_number = "";
          this.groupName = "";
        }
        if (type && item && item === "in") {
          this.expandRowKeys = [];
          this.expandRowKeys.push(this.workshop);
          this.company_id = "";
          this.serial_number = "";
          this.groupName = "";
        }
      });
      loading.close();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pagesize = val;
      this.getSerial("", "type");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getSerial("", "type");
    },
    row_key(row) {
      return row.id;
    },
    expandChange(row, rowList) {
      this.expandRowKeys = [];
      rowList.forEach((item) => {
        this.expandRowKeys.push(item.id);
      });
    },
    rowClick(row, event, column) {
      // eslint-disable-next-line no-extend-native
      Array.prototype.remove = function (val) {
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };

      if (this.expandRowKeys.indexOf(row.id) < 0) {
        this.expandRowKeys.push(row.id);
      } else {
        this.expandRowKeys.remove(row.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialogs /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.dialogs /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}
.dialogs /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.dialogs /deep/ .el-dialog {
  border-radius: 20px !important;
  margin-top: 30vh !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialogs /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}
.dialogs /deep/ .el-form-item__content {
  line-height: 35px;
}
.mgr {
  margin-right: 0px !important;
  margin-left: 34px;
}
@media (max-width: 1367px) {
  .machine_search_list {
    flex: 1.75 !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1441px) {
  .machine_search_list {
    flex: 1.45 !important;
  }
  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}
@media (min-width: 1441px) and (max-width: 1661px) {
  .machine_search_list {
    flex: 1.7 !important;
  }
}
@media (min-width: 1661px) and (max-width: 1919px) {
  .machine_search_list {
    flex: 1.6 !important;
  }
}
.pagination-container {
  margin: 20px 25px;
  float: left;
}
.dialog /deep/ .el-form-item__content {
  line-height: 35px;
  font-size: 18px;
}
.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  margin-top: 30vh !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}
.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}
.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}
.dialog /deep/ .el-input--suffix {
  width: 100% !important;
}
.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}
.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}
.dialogs /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}
.dialog /deep/ .el-dialog__body {
  padding: 20px 20px;
}
.dialog /deep/ .el-select {
  width: 100%;
}
.dialog /deep/ .el-select .el-input .el-select__caret.is-reverse {
  margin-top: -3px;
}
.dialog /deep/ .el-select .el-input .el-select__caret {
  margin-top: 4px;
}
.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}
.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}

.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;
    .table_content {
      height: calc(100vh - 200px);
      overflow: auto;
      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;

        background-color: #ebecee;
      }
      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
    }
    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }
    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 0px 25px;
      .machine_search_list {
        flex: 1.25;
        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }
        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;

          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }
          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }
          /deep/ .el-input__suffix-inner .el-input__icon {
            margin-top: -2px;
          }
          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }
          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }
      .machine_search_btn {
        flex: 0.3;
        .search_btn {
          width: 70px;
          height: 31px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 31px;
          border-radius: 30px;
          margin-top: 12px;
          margin-right: 10px;
          cursor: pointer;
        }
        .edi_color {
          background-color: #767e95;
        }
      }
      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
